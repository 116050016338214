import { call, delay, put, select } from "redux-saga/effects";
import axios from "axios";
import {
  AddChargingProfileData,
  AddNewChargePointData,
  AddOCPPTagData,
  ChargingStationMapListView,
  ChargingStationSearchKeyword,
  GetChargeBoxDetails,
  GetChargePointDetailsById,
  GetChargingControllerCommand,
  GetChargingProfileDetails,
  GetChargingStationDetails,
  GetChargingStationDetailsById,
  GetChargingSummary,
  GetConnectorData,
  GetOCPPTagDetails,
  GetOcppTagDetailsById,
  GetReservationData,
  GetTransactionBulkData,
  GetTransactionData,
  GetTransactionMeterValues,
  GetchargingProfileDetailsById,
  GetschedulePerioddetailsbyprofileId,
  OCPPTagSearch,
  StoreNewChargingStationDetails,
  StorePaymentHistory,
  StoreScheduledPeriodDetails,
  UpdateChargePointDataById,
  UpdateChargingProfileDetailsById,
  UpdateChargingStationDetailsById,
  UpdateOcppTagDataById,
  UpdatePaymentConfigurationDetailsById,
  UpdateSchedulePeriodData,
} from "../../actions/chargingstation/index";
import { getToken } from "../../authentication";
import { message } from "antd";

export const chargingStationBaseUrl =
  // "http://chargingstation-node-2-env-1.eba-cmumfwqr.us-east-1.elasticbeanstalk.com:8080/command-center-services/v1/api";
  // "https://9ywy82semc.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api"
  "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services";

export const baseUrl =
  "http://ev-charging-station-env.eba-yxcgi27v.us-east-1.elasticbeanstalk.com:8080/command-center-services/v1/api";

export function* getChargingStationDetails(params: any) {
  try {
    let { data } = yield call(getChargingStatinDetailsApi, params);
    yield put({
      type: "STORE_CHARGING_STATION_DETAILS",
      payload: data,
    });
  } catch (err) {
    console.error("Unable to fetch charging Station Details", err);
  }
}

export function* getPaymentConfigurationDetails(params: any) {
  try {
    let { data } = yield call(getPaymentConfigurationDetailsApi, params);
    yield put({
      type: "STORE_PAYMENT_CONFIGURATION_DETAILS",
      payload: data,
    });
  } catch (err) {
    console.error("Unable to fetch charging Station Details", err);
  }
}

export function* addRate(params: any) {
  try {
    let { data } = yield call(addRateDetailsApi, params);
    yield put({
      type: "STORE_RATE_DETAILS",
      payload: data,
    });
  } catch (err) {
    console.error("Unable to fetch charging Station Details", err);
  }
}
export function* getMapViewStatus(params: any) {
  try {
    console.log("MAPVIEW ACTION IN SAGA", params.payload); // Debug log
    yield put({
      type: "STORE_TOGGLE__MAP_LIST_VIEW",
      payload: params.payload,
    });
  } catch (err) {
    console.error("Error in Saga", err);
  }
}

export function* getChargingStationDetailsBySearchName(params: {
  type: string;
  payload: { keyword?: string; orgId?: string };
}) {
  try {
    const searchTerm = params.payload?.keyword?.trim() || "";
    const orgId = params.payload?.orgId;
    if (!searchTerm) {
      console.log("No search keyword provided, fetching all stations.");
      return;
    }
    const { data } = yield call(getChargingStationsListByNameFromApi, {
      payload: { keyword: searchTerm, orgId },
    });
    yield put({
      type: "STORE_CHARGING_STATION_SEARCH_KEYWORD",
      payload: data,
    });
  } catch (err) {
    console.error("Unable to fetch charging station details:", err);
  }
}

export function* AddNewStation(params: StoreNewChargingStationDetails) {
  try {
    // console.log("API call is in progress");
    let { data } = yield call(addChargingStationApi, params);
  } catch (err) {
    console.error("unable to add Station", err);
  }
}
export function* getChargingStationById(params: GetChargingStationDetailsById) {
  try {
    let { data } = yield call(getChargingDetailsByIdApi, params);
    yield put({
      type: "STORE_CHARGING_DETAILS_BY_ID",
      payload: data,
    });
  } catch (err) {
    console.error("Unable to get data", err);
  }
}

export function* updateChargingDetailsById(
  params: UpdateChargingStationDetailsById
) {
  try {
    let { data } = yield call(updateChargingDetailsByIdApi, params);
    // console.log("API Call", data);
    yield put({
      type: "STORE_CHARGING_DETAILS_BY_ID",
      payload: data,
    });
  } catch (err) {
    console.error("Unable to get data", err);
  }
}
export function* GetReservationDetails(params: GetReservationData) {
  try {
    // console.log("API call is in progress");

    let { data } = yield call(getReservationDetailsApi, params);
    yield put({ type: "STORE_RESERVATION_DATA", payload: data });
  } catch (err) {
    console.log("unable to get Data", err);
  }
}

export function* GetTransactionDetails(params: GetTransactionData) {
  try {
    // console.log("API call is in progress");
    let { data } = yield call(getTransactionDetailsApi, params);
    // yield delay(60000);
    console.log("TransactionData", data);
    yield put({
      type: "STORE_TRANSACTION_DATA",
      payload: data,
    });
  } catch (err) {
    console.log("unable to get Data", err);
  }
}
export function* GetTransactionBulkDetails(params: GetTransactionBulkData) {
  try {
    // console.log("API call is in progress");
    let { data } = yield call(getTransactionBulkDetailsApi, params);
    // yield delay(60000);
    console.log("TransactionData", data);
    yield put({
      type: "STORE_TRANSACTION_DATA",
      payload: data,
    });
  } catch (err) {
    console.log("unable to get Data", err);
  }
}

export function* pollTransactionDetails(params: GetTransactionData) {
  while (true) {
    yield call(GetTransactionDetails, params);
  }
}
export function* getOCPPTagData(params: GetOCPPTagDetails) {
  try {
    let { data } = yield call(getOCPPTagDataApi, params);
    yield put({
      type: "STORE_OCPP_TAG_DETAILS",
      payload: data,
    });
  } catch (err) {
    console.log("unable to get Data", err);
  }
}
export function* getTransactionMeterValuesByID(params: any) {
  try {
    console.log("MeterValues");
    let { data } = yield call(getTransactionByIDApi, params);
    yield put({
      type: "STORE_TRANSACTION_METER_VALUES",
      payload: data,
    });
    console.log("MeterValues 1", data);
  } catch (err) {
    console.log("MeterValues");

    console.error("Unable to get data", err);
  }
}
export function* getOCPPTagSearchData(params: OCPPTagSearch) {
  try {
    let { data } = yield call(getOCPPTagSearchByTagId, params);
    yield put({
      type: "STORE_OCPP_TAG_DETAILS",
      payload: data,
    });
  } catch (err) {
    console.log("unable to get Data", err);
  }
}
export function* addnewOcppTagData(params: AddOCPPTagData) {
  try {
    // console.log("API call in progress");
    let { data } = yield call(addNewOcppTagDataApi, params);
  } catch (err) {
    console.error("Error", err);
  }
}

export function* getOcppTagDatabyId(params: GetOcppTagDetailsById) {
  try {
    let { data } = yield call(getOCPPTagDataByIdApi, params);
    // console.log("Data", data);
    yield put({ type: "STORE_OCPP_TAG_DETAILS_BY_ID", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}

export function* updateOcppDetailsById(params: UpdateOcppTagDataById) {
  try {
    let { data } = yield call(updateOcppDetailsByIdApi, params);
    // console.log("Update Data", data);
    yield put({ type: "STORE_OCPP_TAG_DETAILS_BY_ID", payload: data });
  } catch (err) {
    console.error("Unable to get data", err);
  }
}

export function* updatePaymentConfigurationDetailsById(params: UpdatePaymentConfigurationDetailsById) {
  try {
    let { data } = yield call(updatePaymentConfigurationDetailsByIdApi, params);
    // console.log("Update Data", data);
    yield put({ type: "STORE_PAYMENT_CONFIGURATION_DETAILS_BY_ID", payload: data });
  } catch (err) {
    console.error("Unable to get data", err);
  }
}


export function* getChargingProfileData(params: GetChargingProfileDetails) {
  try {
    let { data } = yield call(getChargingProfileDataApi, params);
    // console.log("API data", data);
    yield put({ type: "STORE_CHARGING_PROFILE_DETAILS", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}

export function* addNewChargingProfileData(params: AddChargingProfileData) {
  try {
    let { data } = yield call(addNewChargingProfileDataApi, params);
  } catch (err) {
    console.error("Error", err);
  }
}

export function* getchargingProfileDataById(
  params: GetchargingProfileDetailsById
) {
  try {
    // console.log("API call started");
    let { data } = yield call(getchargingProfileDataByIdApi, params);
    // console.log("Data", data);
    yield put({ type: "STORE_CHARGING_PROFILE_DETAILS_BY_ID", payload: data });
  } catch (err) {
    console.error("Unable to get data", err);
  }
}
export function* updatechargeProfileDataById(
  params: UpdateChargingProfileDetailsById
) {
  try {
    // console.log("Api call started");
    let { data } = yield call(updatechargeProfileDataByIdAPi, params);
    yield put({ type: "STORE_CHARGING_PROFILE_DETAILS_BY_ID", payload: data });
  } catch (err) {
    console.error("Unable to get data", err);
  }
}
export function* storeConnectorDetails(params: GetConnectorData) {
  try {
    let { data } = yield call(getConnectorDetailsApi, params);
    yield put({ type: "STORE_CONNECTOR_DETAILS", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}
export function* getChargeBoxDetails(params: GetChargeBoxDetails) {
  try {
    let { data } = yield call(getChargeBoxApi, params);
    // console.log("Data", data);
    yield put({ type: "STORE_CHARGE_POINT_DETAILS", payload: data });
  } catch (err) {
    console.error("error", err);
  }
}
export function* addNewChargeBoxDetails(params: AddNewChargePointData) {
  try {
    let { data } = yield call(addChargeBoxApi, params);
  } catch (err) {
    console.error("Error", err);
  }
}

export function* getchargePointDataById(params: GetChargePointDetailsById) {
  try {
    // console.log("Api call started");
    let { data } = yield call(getchargePointDataByIdApi, params);
    // console.log("Data", data);
    yield put({ type: "STORE_CHARGE_POINT_DETAILS", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}

export function* updateChargeboxDataById(params: UpdateChargePointDataById) {
  try {
    // console.log("Api call started");
    let { data } = yield call(updateChargePointDetailsByIDApi, params);
    // console.log("Data", data);
    yield put({ type: "STORE_CHARGE_POINT_DETAILS", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}
export function* storeChargingSummaryDetails(params: GetChargingSummary) {
  try {
    // console.log("Api call started");
    let { data } = yield call(getchargingSummaryApi, params);
    // console.log("Data", data);
    yield put({ type: "STORE_CHARGING_SUMMARY_DETAILS", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}
export function* getchargingschedulebyprofileId(
  params: GetschedulePerioddetailsbyprofileId
) {
  try {
    let { data } = yield call(getscheduleperiodbyprofileIdApi, params);
    yield put({
      type: "STORE_SCHEDULE_PERIOD_DETAILS_BY_PROFILE_ID",
      payload: data,
    });
  } catch (err) {
    console.error("Error", err);
  }
}
export function* addNewChargingSchedule(params: StoreScheduledPeriodDetails) {
  try {
    let { data } = yield call(addNewChargingscheduleApi, params);
    // console.log("Data", data);
  } catch (err) {
    console.error("error", err);
  }
}
export function* updateSchedulePeriodData(params: UpdateSchedulePeriodData) {
  try {
    let { data } = yield call(updateSchedulePeriodDetailsApi, params);
    yield put({
      type: "STORE_SCHEDULE_PERIOD_DETAILS_BY_PROFILE_ID",
      payload: data,
    });
  } catch (err) {
    console.error("Error", err);
  }
}
export function* getChargeControllerCommandData(
  params: GetChargingControllerCommand
) {
  try {
    let { data } = yield call(getchargingControllerSetailsApi, params);
    yield put({ type: "STORE_CHARGING_CONTROLLER_COMMANDS", payload: data });
  } catch (err) {
    console.error("Error", err);
  }
}
// API Call
export async function getChargingStatinDetailsApi(params: any) {
  const orgId = params.payload;

  const tokenRes = await getToken();
  console.log("token", orgId, params.payload);
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/charge-stations/by-orgId/${orgId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("StationListresponse", response.data);
  return response.data;
}

export async function getPaymentConfigurationDetailsApi(params: any) {
  const orgId = params.payload;

  const tokenRes = await getToken();
  console.log("token", orgId, params.payload);
  const response = await axios.post(
    `${process.env.REACT_APP_WEBAPPAPI}/getAllPaymentDetails`,
    // "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/charge-stations",

    {
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      data: {
        platForm: "Web",
        orgId:params.payload
      },
    }
  );
  console.log("StationListresponse", response.data);
  return response.data;
}

export async function addRateDetailsApi(params: any) {
  const orgId = params.payload;

  const tokenRes = await getToken();
  console.log("token", orgId, params.payload);
  const response = await axios.post(
    `${process.env.REACT_APP_WEBAPPAPI}/savePaymentDetails`,
    // "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/charge-stations",
    {
      
        chargeboxId: params.payload.chargeboxId,
        location: params.payload.location,
        stationName: params.payload.stationName,
        chargeCost: params.payload.chargeCost,
        platForm:params.payload.platForm,
        orgId:params.payload.orgId
      
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
   
  );
  console.log("StationListresponse", response.data);
  if(response.data.status === "FAILED"){
    message.warn("Payment configuration already exists for selected chargebox")  
  }
  return response.data;
}

export async function addChargingStationApi(values: any) {
  // console.log("API CALL STARTED");
  const tokenRes = await getToken();
  const response = await axios.post(
    `${chargingStationBaseUrl}/v1/api/charge-stations`,
    // "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/charge-stations",
    values,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("response", response);
  return response.data;
}

export async function getChargingDetailsByIdApi(
  params: GetChargingStationDetailsById
) {
  const tokenRes = await getToken();
  const chargeStationId = params.payload;
  // console.log("Id", stationId);
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/charge-stations/${chargeStationId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  return response.data;
}
export async function updateChargingDetailsByIdApi(
  params: UpdateChargingStationDetailsById
) {
  const { id, data } = params.payload;
  // console.log("Id", id);
  // console.log("payload data", data);
  const tokenRes = await getToken();
  const response = await axios.put(
    `${chargingStationBaseUrl}/v1/api/charge-stations/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  return response.data;
}
export async function getReservationDetailsApi(params: GetReservationData) {
  // console.log("RESERvation", params.payload);
  const orgId = params.payload;
  // console.log("API call started");
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/organizations/${orgId}/reservations`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("Resevation API", response);
  return response.data;
}
export async function getTransactionDetailsApi(params: GetTransactionData) {
  // console.log("API call started");
  const orgId = params.payload;
  console.log("PAYLOAD", params.payload);
  const tokenRes = await getToken();
  const response = await axios.post(
    // `${chargingStationBaseUrl}/v1/api/organizations/${orgId}/transactions`,
    "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/transactionsByDateTime",
    // params.payload,
    {
      orgId: params.payload.orgId,
      customerId: params.payload.customerId,
      startTime: params.payload.startTime,
      stopTime: params.payload.stopTime,
      timeZone: params.payload.timeZone,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("Transaction Api", response);
  return response.data;
}
export async function getTransactionBulkDetailsApi(
  params: GetTransactionBulkData
) {
  // console.log("API call started");
  const orgId = params.payload.orgId;
  const browserTimeZone = params.payload.browserTimeZone;
  console.log("PAYLOAD", params.payload);
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/organizations/${orgId}/transactions?timeZone=${browserTimeZone}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("Transaction Api", response);
  return response.data;
}
export async function getOCPPTagDataApi(params: GetOCPPTagDetails) {
  // console.log("API call started");
  const orgId = params.payload;
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/ocpp-tags/by-orgId/${orgId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  return response.data;
}

export async function addNewOcppTagDataApi(values: any) {
  // console.log("API call started");
  const tokenRes = await getToken();
  const response = await axios.post(
    `${chargingStationBaseUrl}/v1/api/ocpp-tags`,
    values,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("response", response);
  return response.data;
}
export async function getOCPPTagDataByIdApi(params: GetOcppTagDetailsById) {
  const ocppTagUuid = params.payload;
  // console.log("API call started");
  // console.log("ID", params.payload);
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/ocpp-tags/${ocppTagUuid}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  return response.data;
}
export async function updateOcppDetailsByIdApi(params: UpdateOcppTagDataById) {
  // console.log("API CALL STARTED");

  const { id, data } = params.payload;
  const tokenRes = await getToken();
  const response = await axios.put(
    `${chargingStationBaseUrl}/v1/api/ocpp-tags/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("resp", response);
  return response.data;
}

export async function updatePaymentConfigurationDetailsByIdApi(params: UpdatePaymentConfigurationDetailsById) {
  // console.log("API CALL STARTED");

 
  console.log(params.payload,"shsh")
  const tokenRes = await getToken();
  const response = await axios.post(
    `${process.env.REACT_APP_WEBAPPAPI}/updateChargeCost`,
    
      {
        chargeboxId: params.payload.chargeboxId,
        chargeCost: params.payload.chargeCost,
        platForm: "Web",
        orgId: params.payload.orgId
    
    },
    {
      headers: {
        // "Content-Type": "application/json",
        // Authorization: tokenRes.token,
       
      },
    }
  );
  // console.log("resp", response);
  return response.data;
}
export async function getOCPPTagSearchByTagId(params: OCPPTagSearch) {
  console.log("OCPPSEARCH PARAMS API", params);

  const tagId = params.payload;
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/ocpp-tags/by-tagId/${tagId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("resp", response);
  return response.data;
}
export async function getChargingProfileDataApi(
  params: GetChargingProfileDetails
) {
  // console.log("API call started");
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/charging-profiles`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  return response.data;
}
export async function addNewChargingProfileDataApi(values: any) {
  // console.log("API call started");
  const tokenRes = await getToken();
  // console.log("values", values);
  try {
    const response = await axios.post(
      `${chargingStationBaseUrl}/v1/api/charging-profiles`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token,
          "X-source": "Web",
          "X-vendor": "OCPP",
        },
      }
    );
    // console.log("response", response);
    return response.data;
  } catch (error) {
    // console.error("Error during API call:", error.response || error.message);
    throw error; // rethrow the error to propagate it up the call stack
  }
}
export async function getchargingProfileDataByIdApi(
  params: GetchargingProfileDetailsById
) {
  // console.log("API call started");
  const chargingProfilePk = params.payload;
  // console.log("Payload", chargingProfilePk);
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/charging-profiles/${chargingProfilePk}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  return response.data;
}
export async function updatechargeProfileDataByIdAPi(
  params: UpdateChargingProfileDetailsById
) {
  // console.log("API call started");
  const { id, data } = params.payload;
  // console.log("ID", id);
  // console.log("Payload Data", data);

  try {
    const tokenRes = await getToken();
    const response = await axios.put(
      `${chargingStationBaseUrl}/v1/api/charging-profiles/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token,
          "X-source": "Web",
          "X-vendor": "OCPP",
        },
      }
    );

    // console.log("RESPONSE", response.data);
    return response.data;
  } catch (error) {
    // Handle errors
    // console.error(
    //   "Error in API call:",
    //   error.response || error.message || error
    // );
    throw error; // Re-throw the error for the calling code to handle if needed
  }
}

export async function getscheduleperiodbyprofileIdApi(
  params: GetschedulePerioddetailsbyprofileId
) {
  // console.log("API call started");
  const chargingProfileId = params.payload;
  const tokenRes = await getToken();
  try {
    const response = await axios.get(
      `${chargingStationBaseUrl}/v1/api/charging-profiles/${chargingProfileId}/charging-schedules`,
      // "/v1/api/charging-profiles/{chargingProfileId}/charging-schedules",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token,
          "X-source": "Web",
          "X-vendor": "OCPP",
        },
      }
    );
    // console.log("RESPONSE", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in API request:", error);
    throw error; // Re-throw the error to propagate it to the calling code
  }
}

export async function addNewChargingscheduleApi(values: any) {
  // console.log("API call started");
  // console.log("payload", values);
  const tokenRes = await getToken();
  try {
    const response = await axios.post(
      `${chargingStationBaseUrl}/v1/api/charging-schedules`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenRes.token,
          "X-source": "Web",
          "X-vendor": "OCPP",
        },
      }
    );
    console.log("RESPONSE", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in API request:", error);
    throw error; // Re-throw the error to propagate it to the calling code
  }
}

export async function updateSchedulePeriodDetailsApi(
  params: UpdateSchedulePeriodData
) {
  // console.log("API call started");
  const { id, data } = params.payload;
  // console.log("payloadData", data);
  // console.log("ID", id);
  const tokenRes = await getToken();
  const response = await axios.put(
    `${chargingStationBaseUrl}/v1/api/charging-schedules`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("RESPONSE", response.data);
  return response.data;
}

export async function getChargeBoxApi(params: GetChargeBoxDetails) {
  // console.log("API call started");
  const tokenRes = await getToken();
  const response = await axios.get(
    // `${chargingStationBaseUrl}/v1/api/charge-boxes`,
    "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/charge-boxes",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("resp", response);
  return response.data;
}
export async function addChargeBoxApi(values: any) {
  // console.log("API call started");
  console.log("payload", values);
  const tokenRes = await getToken();
  const response = await axios.post(
    // `${chargingStationBaseUrl}/v1/api/charge-box`,
    "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/charge-boxes",
    values,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("resp", response);
  return response.data;
}
export async function getchargePointDataByIdApi(
  params: GetChargePointDetailsById
) {
  // console.log("Api call in progress");

  const chargeBoxPk = params.payload;
  // console.log("payload", chargeBoxPk);
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/charge-boxes/${chargeBoxPk}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("Response ", response);
  return response.data;
}

export async function updateChargePointDetailsByIDApi(
  params: UpdateChargePointDataById
) {
  const { id, data } = params.payload;
  // console.log("API call started");
  console.log("payload", params.payload);
  const tokenRes = await getToken();
  const response = await axios.put(
    `${chargingStationBaseUrl}/v1/api/charge-boxes/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("response", response);

  return response.data;
}
export async function getConnectorDetailsApi(params: GetConnectorData) {
  // console.log("API call started");
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/connectors`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  // console.log("connectors", response);
  return response.data;
}
export async function getchargingSummaryApi(params: GetChargingSummary) {
  const tokenRes = await getToken();
  const response = await axios.post(
    "https://pj19534xx7.execute-api.us-east-1.amazonaws.com/command-center-services/v1/api/charge-stations/summary",
    { org_id: params.payload.orgId, customer_id: params.payload.customer_id },
    {
      headers: {
        Authorization: tokenRes.token!,
        "Content-Type": "application/json",
        // "X-source": "Web",
        // "X-vendor": "OCPP",
      },
    }
  );
  console.log("RESPCHARING", response.data);
  return response.data;
}
export async function getchargingControllerSetailsApi(
  params: GetChargingControllerCommand
) {
  const orgId = params.payload.orgId
  console.log("CONTROLLER",params,orgId)
  const tokenRes = await getToken();
  const response = await axios.get(
    `https://pz1pokh8x1.execute-api.us-east-1.amazonaws.com/yantrawebapp/getPricingProfile?org_id=${orgId}`,
    {
      headers: {
        Authorization: tokenRes.token!,
        "Content-Type": "application/json",
        // "X-source": "Web",
        // "X-vendor": "OCPP",
      },
    }
  );
  console.log("RESPCHARING", response.data);
  return response.data;
}
export async function getChargingStationsListByNameFromApi(params: any) {
  const stationName = params.payload.keyword;
  const orgId = params.payload.orgId;
  console.log("SEARCHPARAMS PARAMS", params, stationName, orgId);
  const tokenRes = await getToken();
  const response = await axios.get(
    chargingStationBaseUrl +
      "/v1/api/charge-stations/by-name/" +
      stationName +
      "?orgId=" +
      orgId,

    {
      headers: {
        Authorization: tokenRes.token!,
        "Content-Type": "application/json",
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("SEARCHPARAMS RESPONSE", response.data);
  return response.data;
}
export async function getTransactionByIDApi(params: any) {
  console.log("Transaction MeterValues PARAMS", params);
  const tokenRes = await getToken();
  const response = await axios.post(
    chargingStationBaseUrl + "/v1/api/transactionsMeterValues",
    params.payload,
    {
      headers: {
        Authorization: tokenRes.token!,
        "Content-Type": "application/json",
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("Transaction MeterValues RESPONSE", response.data);
  return response;
}

export function* getPaymentHistoryDetails(params: StorePaymentHistory) {
  try {
    // console.log("API call is in progress");
    let { data } = yield call(getPaymentHistoryDetailsApi, params);
    // yield delay(60000);
    console.log("TransactionData", data);
    yield put({
      type: "STORE_PAYMENT_HISTORY",
      payload: data,
    });
  } catch (err) {
    console.log("unable to get Data", err);
  }
}

export async function getPaymentHistoryDetailsApi(
  params: StorePaymentHistory
) {
  // console.log("API call started");
  const orgId = params.payload.orgId;
  const browserTimeZone = params.payload.browserTimeZone;
  console.log("PAYLOAD", params.payload);
  const tokenRes = await getToken();
  const response = await axios.get(
    `${chargingStationBaseUrl}/v1/api/transactions-history?orgId=${params.payload.orgId}&ocppTagId=${params.payload.ocppTagId}&page=${params.payload.page}&size=100`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token,
        "X-source": "Web",
        "X-vendor": "OCPP",
      },
    }
  );
  console.log("Transaction Api", response);
  return response.data;
}

